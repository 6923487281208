.address-bubble {
  text-align: left;
  padding: 1em 1.5em;
  border-radius: 0.25rem;
  background-color: #e3ecf1;
  margin-bottom: 1rem;
  border: 1px solid #3fc3d8;
}
.address-bubble p {
  margin: 0;
}
