.logo-bubble {
  text-align: center;
  background-color: #fff;
  border: 4px solid #dfe9ed;
  border-radius: 10px;
  margin-top: 2em;
  margin-bottom: 1em;
  padding: 2.5em 2em;
}
.logo-bubble__logo--isp > img {
  max-height: 2em;
}
.logo-bubble__content {
  padding-top: 1em;
}
.logo-bubble__content--isp p {
  margin-bottom: 0.25em;
}
